@use "constants";
@use "mixins";

@function strip-units($number) {
	// Strips units from string e.g. 15px -> 15
	@return $number / ($number * 0 + 1);
}

@mixin gen-clamp(
	$propName,
	$minFontSizePx,
	$maxFontSizePx,
	$minWidthPx: constants.$bp-tiny,
	$maxWidthPx: constants.$bp-very-large
) {
	$minWidth: strip-units($minWidthPx) / 16;
	$maxWidth: strip-units($maxWidthPx) / 16;
	$minFontSize: strip-units($minFontSizePx) / 16;
	$maxFontSize: strip-units($maxFontSizePx) / 16;

	$slope: ($maxFontSize - $minFontSize) / ($maxWidth - $minWidth);
	$yAxisIntersection: -$minWidth * $slope + $minFontSize;

	#{$propName}: clamp(
		#{$minFontSize * 1rem},
		#{($yAxisIntersection * 1rem)} + " + " + #{($slope * 100vw)},
		#{$maxFontSize * 1rem}
	);

	// If these varaible names change, update them in updateClampedCSS.js
	@at-root {
		:export {
			#{$propName}_min-width: #{strip-units($minWidthPx)};
			#{$propName}_max-width: #{strip-units($maxWidthPx)};
			#{$propName}_min-fs: #{strip-units($minFontSizePx)};
			#{$propName}_max-fs: #{strip-units($maxFontSizePx)};
		}
	}
}

:root {
	--t-text-color: #606060;
	--t-accent-color: #bd2f26;
	--t-accent-color-light: lighten(#e43453, 50%);
	--t-page-bg-color: white;

	--p-rhythm: min(5vw, 15px);
	--p-rhythm-sm: calc(var(--p-rhythm) / 2);
	--p-rhythm-lg: calc(var(--p-rhythm) * 2);
	--page-gutter: min(5vw, 30px);
	--content-gutter: min(5vw, 35px);
	--page-width: min(calc(100vw - var(--page-gutter) * 2), 1790px);

	@include gen-clamp("--fs-body", 22px, 30px);
	@include gen-clamp("--fs-papa", 60px, 100px);
	@include gen-clamp("--fs-title", 40px, 80px);
	@include gen-clamp("--fs-subtitle", 25px, 50px);

	@include mixins.from-md {
		--p-rhythm: 25px;
		--content-gutter: 100px;
	}
}

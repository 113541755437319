@use "constants";
@use "mixins";

@mixin heading-font() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: constants.$heading-font;
	letter-spacing: 1;
}

@mixin body-font() {
	letter-spacing: 1;
	font-weight: 300;
	font-family: constants.$body-font;
}

@mixin base-heading() {
	@include heading-font;
	line-height: 1;
	margin: 0;
}

@mixin base-link() {
	transition: border-color 150ms ease-in;
	color: inherit;
	display: inline-block;
	position: relative;
	border-bottom: 3px solid black;
	&:hover,
	&:focus,
	&:active {
		border-bottom-color: grey;
	}
}

@use "mixins";

.l-page {
	max-width: var(--page-width);
	margin: 0 auto;

	&__section + &__section {
		margin-top: var(--p-rhythm-lg);
	}
}

@use "mixins";

.l-site-footer {
	color: white;
	padding: calc(var(--content-gutter) / 2) var(--content-gutter);
	background-color: var(--t-accent-color);
	&__colophon {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__left {
		display: flex;
		align-items: center;
		margin-bottom: calc(var(--p-rhythm) * 1.5);
	}

	&__right {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
	}
	&__papa {
		width: 310px;
		height: 310px;
		margin-right: calc(var(--p-rhythm) * 1.5);
	}

	&__contact {
		font-weight: bold;
	}

	&__logo {
		width: 200px;
	}
	&__authorised {
		font-size: 20px;
		flex-basis: 100%;
		text-align: right;
	}

	@include mixins.to-md {
		padding: var(--content-gutter);
		&__papa {
			width: 100px;
			height: 100px;
			margin-bottom: var(--p-rhythm);
		}
		&__left,
		&__colophon {
			flex-direction: column;
			align-items: flex-start;
		}

		&__right {
			align-items: flex-start;
		}

		&__authorised,
		&__logo {
			margin-top: calc(var(--p-rhythm) / 2);
		}

		&__contact {
			& * {
				font-size: 18px;
			}
		}

		&__logo {
			width: 100px;
		}

		&__authorised {
			line-height: 1.1;
			font-size: 15px;
			text-align: left;
		}
	}
}

@use "constants";
@use "mixins";
@use "global/bases";

.contact-section,
.content-section {
	display: flex;
	flex-direction: column;

	&__heading {
		@include bases.base-heading;
		font-size: var(--fs-title);
		font-weight: 300;
		color: var(--t-accent-color);
		margin-bottom: var(--p-rhythm-lg);
	}

	@include mixins.from-md {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

.content-section {
	padding: calc(var(--content-gutter) * 4) 0;

	&__signature {
		width: 86px;
		height: 117px;
		margin-top: var(--p-rhythm-lg);
	}

	&__image {
		padding: 0 var(--content-gutter);
		margin-bottom: calc(var(--content-gutter) * 2);
		& > img {
			border-radius: 100%;
		}
	}

	@include mixins.from-md {
		&__text {
			padding-right: var(--content-gutter);
		}
		&__image {
			margin-bottom: 0;
		}
		&__signature {
			width: 115px;
			height: 156px;
		}
	}
}

.contact-section {
	background-color: #f4f3f2;
	position: relative;

	&__text {
		padding: 120px var(--content-gutter);
	}

	&__map {
		height: 100%;
		width: 100%;
		position: relative;
	}

	@include mixins.to-md {
		&__text {
			order: 2;
			padding: var(--content-gutter);
		}

		&__map {
			order: 1;
		}
	}
}

.map-canvas {
	min-height: 340px;
	width: 100%;
	height: 100%;
}

@use "constants";
@use "mixins";

.l-site-header {
	position: relative;
	z-index: 100;

	max-width: var(--page-width);
	margin: 0 auto;
	margin-top: var(--page-gutter);
	&__logo {
		display: block;
		height: 32px;
		img {
			width: auto;
			height: 100%;
		}
	}

	&__menu-trigger {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 3;
	}

	.l-navigation {
		z-index: 2;
	}
}

@include mixins.from-md {
	.l-site-header {
		display: flex;
		justify-content: space-between;

		&__logo {
			width: 250px;
			height: auto;
			img {
				width: 100%;
				height: auto;
			}
		}

		&__menu-trigger {
			display: none;
		}
	}
}

@use "mixins";
@use "global/bases";

.l-navigation {
	&__item {
		display: block;
		line-height: 1;
		&-link {
			width: auto;
		}
	}
}

@include mixins.to-md {
	.l-navigation {
		transition: transform 400ms ease-in-out;
		height: 100vh;
		width: 100%;
		padding: var(--page-gutter);
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		transform: translateX(100%);
		overflow-y: scroll;
		background: var(--t-page-bg-color);

		&.is-active {
			transform: translateX(0);
		}
		&__item {
			position: relative;
		}

		&__item + &__item {
			margin-top: calc(var(--p-rhythm) / 3);
		}

		.orientation-changing & {
			transition: none;
		}
	}
}

@include mixins.from-md {
	.l-navigation {
		flex: 1;

		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;

		&__item {
			position: relative;
			margin: 0;

			&-link {
				@include bases.base-link;
			}

			.submenu {
				transition: opacity 200ms ease-in-out;
				position: absolute;
				top: 100%;
				left: 0;
				min-width: 18ch;

				opacity: 0;
				pointer-events: none;
				&:hover,
				&:active,
				&:focus {
					.submenu {
						opacity: 1;
						pointer-events: all;
					}
				}
			}

			&:hover,
			&:active,
			&:focus {
				.submenu {
					opacity: 1;
					pointer-events: all;
				}
			}
		}

		&__item + &__item {
			margin-left: var(--p-rhythm-sm);
		}
	}
}

@use "constants";
@use "mixins";
@use "global/bases";

.l-home-fold {
	color: white;
	position: relative;
	display: flex;
	flex-direction: column-reverse;
	&__text {
		background-color: var(--t-accent-color);
		padding: calc(var(--content-gutter) * 2) var(--content-gutter);
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__name {
		@include bases.base-heading;
		font-size: 80px;
		line-height: 0.8;
		margin: var(--p-rhythm) 0 10px 0;
	}

	&__position,
	&__tagline {
		@include bases.base-heading;
		font-size: var(--fs-body);
	}
	&__position {
		font-weight: 300;
		margin-bottom: calc(var(--p-rhythm) * 2);
	}

	&__tagline {
		font-style: italic;
		font-weight: 700;
	}

	&__logo {
		width: 100px;
		height: auto;
	}
	&__banner-image {
		width: 100%;
		height: auto;
	}

	@include mixins.from-md {
		display: block;
		&__text {
			background-color: rgba(#bd2f26, 0.8);
			position: absolute;
			left: 0;
			height: 100%;
			width: 50%;
			padding: 0 var(--content-gutter);
		}

		&__logo {
			width: 200px;
		}

		&__name {
			font-size: var(--fs-papa);
			line-height: 1;
			margin: var(--p-rhythm) 0;
		}

		&__position,
		&__tagline {
			font-size: var(--fs-subtitle);
		}
	}
}
